.header-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 100px;
}

.left-side {
	width: 50%;
}
.left-side p {
	font-size: 11px;
	margin-left: 20px;
	color: #6e6d6d;
	font-weight: 500;
}

.right-side {
	display: flex;
	flex: 1;
	width: 50%;
	margin-left: -5%;
	justify-content: flex-end;
}

.right-side-box {
	width: 180px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.right-side-box p {
	font-size: 10px;
	margin-left: 1px;
	margin-right: 8px;
	color: #6e6d6d;
	font-weight: 500;
}

.custom-dropdown {
	position: absolute;
	top: 100%;
	margin-left: -20%;
	width: 180px;
	background-color: white;
	border: 1px solid #ccc;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	display: none;
}

.dropdown-li {
	border-top: 1px solid #ccc;
	font-size: 12px;
	color: black;
}
.custom-dropdown-item {
	color: #333;
	font-size: 12px;
}

.dropDownIconStyle {
	margin-left: -5px;
	margin-right: -13px;
	color: #9d9c9c;
}

.dropDownleftArrow {
	position: absolute;
	right: 10px;
}

.nameAndEmail {
	margin-top: 10px;
}
.nameAndEmail p {
	font-weight: 10px;
}
.nameAndEmail h5 {
	font-weight: 8px;
}

.nameAndEmailBox {
	margin-bottom: 10px;
}

.custom-dropdown-item li.active {
	background-color: lightblue !important;
}
