@media screen and (max-width: 991px) {
	.home-primary-menu li a {
		font-size: 15px;
	}

	.home-primary-menu li + li {
		margin-left: 10px;
	}
}

@media screen and (max-width: 767px) {
	.home-evaluation-process .evaluation-box .sl-no {
		font-size: 30px;
	}

	header .header-actions {
		flex: 0 0 100%;
		margin-top: 10px;
	}

	.primary-menu-wrapper {
		position: fixed;
		top: 0;
		left: -100%;
		width: 100%;
		height: 100%;
		background-color: #ffffff;
		z-index: 999;
		transition: all 300ms ease-in;
	}

	.primary-menu-wrapper.active {
		left: 0;
	}

	.primary-menu-wrapper .home-primary-menu {
		height: 100%;
	}

	.home-primary-menu li a {
		font-size: 25px;
	}

	.home-primary-menu li + li {
		margin-top: 10px;
	}

	.menu-close {
		position: absolute;
		top: 10px;
		right: 10px;
	}

	.home-banner {
		margin-top: 120px;
	}

	.how-it-works ul {
		flex-wrap: wrap;
	}

	.how-it-works ul li {
		flex: 0 0 100%;
		max-width: 100%;
	}

	.how-it-works ul li.arrow {
		padding: 15px;
	}
}

@media screen and (max-width: 479px) {
	header .btn {
		padding: 8px;
	}
}
