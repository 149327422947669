.no-suggestions {
	position: absolute;
	z-index: 100;
	color: #999;
	padding: 0.5rem;
	background-color: #fff;
	border: 1px solid #999;
	border-top-width: 0;
}

.suggestions {
	position: absolute;
	background-color: #fff;
	z-index: 100;
	border: 1px solid #d5dbe1;
	border-top-width: 0;
	list-style: none;
	margin-top: 0;
	max-height: 143px;
	overflow-y: auto;
	padding-left: 0;
	width: calc(430px + 1rem);
	margin-top: -1rem;
	width: -webkit-fill-available;
}

.suggestions::-webkit-scrollbar {
	width: 0;
}

.suggestions li {
	padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
	background-color: #f7f7f7;
	cursor: pointer;
}

.suggestions li:not(:last-of-type) {
	border-bottom: 1px solid #d5dbe1;
}
