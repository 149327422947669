header.home-header {
	padding: 20px 0;
}

header.home-header .btn {
	padding: 10px 20px;
}

.home-primary-menu li a {
	font-weight: 700;
	color: #000000;
}

.home-primary-menu li + li {
	margin-left: 30px;
	min-width: fit-content;
}

.home-primary-menu li a {
	display: inline-block;
	padding: 10px 0;
	position: relative;
}

.home-primary-menu li a:after {
	content: "";
	position: absolute;
	top: 100%;
	left: 0;
	width: 0%;
	border-radius: 5px;
	height: 2px;
	background-color: #059ce0;
	transition: all 300ms ease-in;
}

.home-primary-menu li a:hover,
.home-primary-menu li a.active {
	color: #059ce0;
}

.home-primary-menu li a:hover::after,
.home-primary-menu li a.active:after {
	width: 45px;
}

/* home-banner  */
.home-banner {
	margin-top: 83px;
	padding: 50px 0;
}

.home-banner .home-banner-content {
	max-width: 425px;
}

.home-evaluation-process .evaluation-box {
	border-radius: 30px;
	padding: 40px 20px 70px;
}

.home-evaluation-process .evaluation-box .sl-no {
	font-size: 50px;
	margin-bottom: 25px;
}

.home-common-section .content {
	max-width: 500px;
}

.stats {
	background: url(/public/images/home/bg-1.png) no-repeat center;
	background-size: cover;
}

.home-testimonials .home-testimonial-box {
	border: 1px solid rgba(223, 223, 223, 1);
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
	overflow: hidden;
}

.home-testimonials .home-testimonial-box img {
	width: 100%;
}

.home-testimonials .home-testimonial-box .client-info {
	bottom: 10px;
	z-index: 2;
	min-width: 75%;
}

.home-testimonials .home-testimonial-box .btn-play {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

footer.home-footer {
	font-size: 20px;
}

footer.home-footer ul li + li {
	margin-top: 5px;
}

.home-banner-employer form {
	max-width: 400px;
}

.home-features-section .feature-box {
	padding: 20px;
	transition: all 200ms ease-in-out;
}

.feature-box .benefit {
	display: flex;
	align-items: center;
	gap: 2em;
}

.home-features-section .feature-box p {
	display: none;
}

.home-features-section .feature-box:hover {
	background-color: #ffffff;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.home-features-section .feature-box:hover p {
	display: block;
}
