.ql-editor {
	height: 20em;
	min-height: 100%;
	font-size: 14px;
	overflow-y: auto;
	border-radius: 0px 0px 5px 5px;
}

.ql-container {
	border-radius: 0px 0px 5px 5px;
}
.quill {
	height: 306px !important;
}
.ql-toolbar.ql-snow {
	padding: 2px;
	color: blue;
	background-color: #eee;
	border-radius: 5px 5px 0px 0px;
}

.ql-snow .ql-stroke {
	stroke: #03a9f4;
}

.App .ql-snow .ql-tooltip.ql-flip {
	width: auto;
	left: 17px !important;
	white-space: normal;
}

@page {
	margin: 1in;
}

@media print {
	body {
		background: none;
	}
	.container .ql-editor {
		width: 6.5in;
		height: 9in;
		padding: 0;
		margin: 0;
		box-shadow: none;
		align-self: flex-start;
	}
	.container .ql-toolbar.ql-snow {
		display: none;
	}
}
