.dashboard-container {
	padding: 0px;
	background-color: #f5f5f5;
}
.nav-bar-collapsed {
	background-color: #f7f7f7;
	width: 60px;
	padding-left: 20px;
	padding-top: 20px;
	position: fixed;
	top: 0;
	bottom: 0;
	height: 100%;
	left: 0;
	position: fixed;
	left: 0;
	overflow-y: auto;
	overflow-x: hidden;
	border-right: 1px solid #e5e5e5;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	font-size: 11px !important;
	z-index: 1000;
}

.dashboard-spann {
	padding-top: 10px;
	height: 70px;
	margin-top: 20px;
	background-color: #f5f5f5;
}
.dashboard-header-title {
	display: flex;
	align-items: center;
	margin-top: 10px;
	padding-left: 20px;
	margin-left: 1%;
	margin-right: 1%;
	width: 98%;
	border-radius: 5px;
	justify-content: space-between;
}
.dashboard-header-title h1 {
	font-size: 16px;
	font-weight: bold;
}
.logo-image {
	width: 100px;
	height: auto;
	margin-right: 20px;
	height: 100%;
	padding-right: 20px;
	object-fit: contain;
}

.dashboard-header p {
	font-size: 16px;
	line-height: 1.5;
}

.dashboard-header {
	display: flex;
	align-items: center;
	margin-top: 10px;
	height: 80px;
	padding-left: 20px;
	margin-left: 1%;
	margin-right: 1%;
	width: 98%;
	border-radius: 5px;
	border: 1px solid #dcdcdc;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
	background-color: #fff;
}

.dashboard-header h1 {
	font-size: 24px;
	margin-bottom: 10px;
}

.dashboard-header h6 {
	font-size: 16px;
	line-height: 0.8;
}

.dashboard-header p {
	font-size: 12px;
	padding: 2px;
	line-height: 1;
}

.container-dashboard {
	display: flex;
	flex-wrap: wrap;
	margin-left: 0.5%;
	width: 99%;
	justify-content: flex-start;
}

.container-dashboard-collapsed {
	display: flex;
	flex-wrap: wrap;
	margin-left: 0.5%;
	width: 99%;
	justify-content: flex-start;
}

.left-column {
	flex: 3;
	min-width: 300px;
	padding: 5px;
	font-size: 16px;
}

.right-column {
	flex: 1;
	min-width: 250px;
	padding: 5px;
}

.segment-dashboard {
	margin-bottom: 10px;
	padding: 20px;
	min-height: 20vmin;
	border: 1px solid #dcdcdc;
	border-radius: 5px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
	background-color: #fff;
}

.segment-dashboard h2 {
	font-size: 14px;
	margin-left: 5px;
	font-weight: bold;
}

.segment-dashboard h3 {
	font-size: 12px;
	font-weight: bold;
	margin-left: 5px;
}
.segment-dashboard h4 {
	font-size: 12px;
	font-weight: 400;
	margin-left: 5px;
}
.segment-dashboard p {
	font-size: 14px;
	margin: 5px;
}

.segment-dashboard2 {
	margin-bottom: 10px;
	flex-direction: row;
	padding: 20px;
	min-height: 20vmin;
	border: 1px solid #f5f5f5;
	border-radius: 4px;
	background-color: #fff;
}

.nav-bar {
	background-color: #fefefe;
	width: 240px;
	padding-left: 10px;
	padding-top: -10px;
	position: fixed;
	top: 0;
	bottom: 0;
	height: 100%;
	left: 0;
	position: fixed;
	left: 0;
	overflow-y: auto;
	overflow-x: hidden;
	border-right: 1px solid #e5e5e5;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	font-size: 11px !important;
	z-index: 1000;
}

.nav-bar ul {
	list-style: none;
	padding-left: 10px;
	margin: 0;
}

.nav-bar ul a {
	color: #505050;
	text-decoration: none;
}

.nav-bar-collapse {
	width: 60px;
	z-index: 1000;
}

.nav-bar li i {
	font-size: 18px;
	margin-right: 12px;
}

.nav-bar li {
	padding: 9px;
	border-radius: 8px;
	display: flex;
	align-items: center;
	z-index: 3;
	margin-right: 20px;
	font-weight: bold;
	position: relative;
}

.nav-bar li:hover i {
	background-color: #e5e5e5;
	transition: all 0.3s ease-in-out;
}

.nav-bar .nav-bar-link.active {
	background-color: #e6f2ff;
	border-radius: 5px;
	width: 100%;
	color: #3f4040;
	transition: all 0.3s ease-in-out;
}

.nav-bar .nav-bar-link.active span {
	width: 100%;
	color: #034366;
	transition: all 0.3s ease-in-out;
}

.nav-bar .nav-bar-link li:hover {
	background-color: #e5e5e5;
	border-radius: 5px;
	width: 100%;
	color: #3f4040;
	transition: all 0.3s ease-in-out;
}

.nav-bar .nav-bar-link span:hover {
	color: #303335;
	transition: all 0.1s ease-in-out;
}

.nav-bar .nav-bar-link i:hover {
	color: #b6b4b4;
	transition: all 0.1s ease-in-out;
}

.nav-bar .nav-bar-link.active i {
	color: #47bbf9;
}

.main-box {
	margin-left: 0px;
	padding-top: 10px;
	margin-left: 240px;
	z-index: 100;
	width: calc(100% - 240px);
	padding-right: 3%;
	min-height: 850px;
}

.main-box-collapsed {
	margin-left: 60px;
	background-color: #ffffff;
	padding-right: 2%;
	min-height: 850px;
	margin-left: 60px;
	width: calc(100% - 60px);
}

.header {
	margin-left: 240px;
	z-index: 1;
	height: 56px;
	width: calc(100% - 240px);
	background-color: white;
	border-bottom: 1px solid #00000021;
	display: flex;
	align-items: center;
}

.navbar-brand {
	width: 70px;
	margin-left: 30px;
	margin-top: 5px;
	margin-bottom: 10px;
	object-fit: contain;
}
.bottom-nav {
	width: 100%;
}
.bottom-nav-collapse-ul {
	width: 100%;
	left: 7%;
	position: absolute;
	bottom: 10px;
}
.bottom-nav-collapse-ul li:hover {
	background-color: #dfe0e1;
}
.bottom-nav-collapse-ul-i {
	width: 100%;
}

.bottom-nav-collapse-ul i:hover {
	background-color: #a6a7a9;
}
.account-data-side-top {
	top: 0;
	right: 0;
	padding: 10px;
	font-weight: bold;
}

.account-data {
	position: fixed;
	margin-top: 60px;
	margin-left: -40px;
	padding: 10px;
	width: 250px;
	border-top: 1px solid #e5e5e5;
	bottom: 50px;
	font-weight: bold;
	z-index: 3;
}

.account-data span {
	margin-left: 10px;
	font-size: 10px;
}

.account-data i {
	margin-top: 10px;
	margin-left: 80px;
	background-color: #ffffff;
}

.top-segment-dashboard-left-list {
	list-style: none;
	padding: 0;
}

.top-segment-dashboard-left-list li {
	height: 30%;
	margin-bottom: 5px;
}

.top-segment-dashboard-left-container {
	display: flex;
	flex-direction: column;
}

.top-segment-dashboard-left-container-head {
	display: flex;
}

.top-segment-dashboard-left-image {
	width: 7.5%;
	padding-top: 2.5%;
	margin-bottom: 1.5%;
}

.top-segment-dashboard-left-details {
	max-width: 90%;
	overflow: hidden;
}

.top-segment-dashboard-left-details-features {
	width: 80%;
	max-width: 80%;
	padding: 2px;
	margin-left: 10px;
	padding-top: 10px;
	margin-top: -2%;
	overflow: hidden;
}
.top-segment-dashboard-left-details-features p {
	font-size: 12px;
	margin-top: -5px;
	font-weight: 600;
	color: #6a6a6a;
}

.top-segment-dashboard-left-details-features h3 {
	font-size: 12px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: 600;
	color: #333333;
}

.top-segment-dashboard-left-details p {
	font-size: 11px;
	white-space: nowrap;
	margin-top: 5px;
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: 600;
	color: #6a6a6a;
}
.top-segment-dashboard-left-details h3 {
	font-size: 12px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: 600;
	color: #333333;
}

.top-segment-dashboard-left-title {
	margin: 5px;
	background-color: #3678e5;
}
.top-segment-dashboard-left-name {
	width: 85%;
	height: 90%;
}

.top-segment-dashboard-left-name h3 {
	margin-top: 12px;
}

.top-segment-dashboard-left-link {
	color: rgb(35, 35, 35);
	width: 10%;
	height: 10%;
	margin-top: 1%;
}

.top-segment-dashboard-left-link i {
	cursor: pointer;
}

.top-segment-dashboard-left-link i:hover {
	color: rgb(155, 153, 153);
}

.recommendations-list,
.features-list {
	list-style: none;
	padding: 0;
}

.recommendations-list li {
	height: 30%;
	margin-bottom: 5px;
}

.features-list li {
	height: 30%;
}

.view-all-button {
	width: 100%;
	height: 30px;
	border-radius: 4px;
	border: none;
	font-size: 14px;
	font-weight: 700;
	color: #333333;
	background-color: #f5f5f5;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow for a smoother appearance */
	cursor: pointer;
	outline: none;
}

.view-all-button:hover {
	background-color: #e0e0e0;
}

.view-all-button {
	width: 100%;
	height: 30px;
	border-radius: 4px;
	border: none;
	font-size: 14px;
	font-weight: 700;
	color: #333333;
	background-color: #f5f5f5;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow for a smoother appearance */
	cursor: pointer;
	outline: none;
}

.view-all-button:hover {
	background-color: #e0e0e0;
}

.learn-more-button {
	width: 150px;
	height: 30px;
	border-radius: 4px;
	font-weight: 700;
	font-size: 14px;
	border: none;
	color: #333333;
	background-color: #f5f5f5; /* Add a background color for better contrast */
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow for a smoother appearance */
	cursor: pointer;
	outline: none;
}

.learn-more-button:hover {
	background-color: #e0e0e0;
}

.dropdown-button {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 30px;
	border-radius: 4px;
	border: none;
	font-size: 14px;
	font-weight: 700;
	color: #333333;
	background-color: #f5f5f5;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	cursor: pointer;
	outline: none;
	padding: 0 10px;
}

.dropdown-button-arrow-side {
	border-radius: 2px;
}

.dropdown-button h2 {
	margin: 0;
	display: flex;
	align-items: center;
}

.dropdown-button-arrow-down {
	width: 5%;
	margin-left: 10px;
}

.dropdown-button-arrow-down i {
	font-size: 16px;
}

.recommended-jobs-wrapper {
	overflow-x: auto;

	height: 250px;
	padding: 10px 0;
	display: flex;
	scrollbar-width: none;
	-ms-overflow-style: none;
}

.recommended-jobs-wrapper::-webkit-scrollbar {
	display: none;
}

.recommended-jobs-container {
	display: flex;
	gap: 15px;
	min-width: max-content;
}

.recommended-job {
	flex: 0 0 auto;
	width: 170px;
	padding: 10px;
	background-color: #c0bebe;
	border-radius: 3px;
}

.recommended-job-img {
	width: 100%;
	height: 70%;
	border-radius: 5px;
	background-color: #5973c2;
}
.colored-text-and-star {
	width: 300px;
	background-color: #5973c2;
	border-radius: 3px;
	color: #fff;
	margin: 10px;
}
.job-details {
	margin-top: 10px;
}
.job-details p {
	font-size: 12px;
}

.segment-dashboard-description {
	padding-top: 20px;
	padding-bottom: 20px;
}

.middle-segment-dashboard-table-title {
	border-top: 1px solid #e5e5e5;
	padding: 10px;
	font-weight: 600;
	font-size: 13px;
}

.middle-segment-dashboard-table-name {
	border-top: 1px solid #e5e5e5;
	padding: 10px;
	font-size: 12px;
}

.middle-segment-dashboard-container-head {
	width: 100%;
}
.middle-segment-dashboard-container-head-img {
	width: 2%;
	padding-top: 3%;
	margin-right: 20px;
	flex: 1;
	justify-content: center;
	align-items: center;
	background-color: #777878;
}

.post-item-in-dashboard {
	box-shadow: 0 1px 8px 0 rgba(148, 146, 146, 0.2);
	transition: 0.3s;
	height: 200px;
	margin: 10px auto;
	border-radius: 5px;
	overflow: hidden;
	z-index: 1;
}

.post-item-in-dashboard:hover {
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.post-item-in-dashboard-container-top {
	padding: 5px 16px;
	background-color: #f5f5f5;
}

.post-item-in-dashboard-container {
	padding: 6%;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100px;
	flex-direction: column;
}

.open-button {
	width: 80%;
	margin-top: 0%;
	margin-left: 10%;
	height: 35px;
	border: none;
	background-color: #50a2e9;
	border-radius: 3px;
	color: #f1f1f1;
	font-size: 14px;
	font-weight: 700;
	border-top: 1px solid #e5e5e5;
	cursor: pointer;
	outline: none;
	transition: background-color 0.3s ease-in-out;
}

.open-button:hover {
	background-color: #e0e0e0;
}

.dropdown-list {
	padding: 10px;
	background-color: #ffffff;
	max-height: 200px;
	overflow-y: auto;
}

.dropdown-list p {
	margin: 5px 0;
	font-size: 12px;
	color: #333333;
	margin-bottom: 20px;
	text-align: justify;
}

@media screen and (max-width: 768px) {
	.container-dashboard {
		display: grid;
		grid-template-columns: 1fr;
		gap: 20px;
	}

	.right-column {
		order: -1;
	}

	.left-column,
	.right-column {
		font-size: 14px;
		flex: 1;
		min-width: 100px;
		padding: 5px;
	}

	.sidebar {
		display: none;
	}

	.nav-bar {
		display: none;
	}
	.nav-bar-collapsed {
		background-color: #f7f7f7;
		width: 60px;
		padding-left: 20px;
		padding-top: 20px;
		position: fixed;
		top: 0;
		bottom: 0;
		height: 100%;
		left: 0;
		position: fixed;
		overflow-y: auto;
		overflow-x: hidden;
		border-right: 1px solid #e5e5e5;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		font-size: 11px !important;
		z-index: 30;
	}
	.main-page {
		width: 100%;
		overflow: hidden;
	}

	.py-0 {
		list-style-type: none;
		padding-left: 20px;
	}

	.main-box {
		margin-left: 20px;
		margin-top: 20px;
		padding-left: 0;
		min-height: 100vh;
		padding: 20;
		padding-right: 0;

		margin-left: 60px;
		width: calc(100% - 60px);
	}

	.account-data-side-top {
		display: none;
	}

	.dashboard-header {
		display: flex;
		align-items: center;
		margin-top: 10px;
		height: 80px;
		padding-left: 20px;
		margin-left: 1%;
		margin-right: 1%;
		width: 98%;
		border-radius: 5px;
		background-color: #ffffff;
	}

	.container-scroll {
		height: 1vh;
		overflow: auto;
	}
}
