.sidebar-li-style {
	width: 100%;
}
.sidebar-li-style:hover {
	background-color: #dcdcdc;
	width: 100%;
}
.iconStyle {
	font-size: 12px;
	color: #8f9ea8;
	margin-right: 5px;
}

.linkText {
	color: black;
	font-weight: 600;
}
.nav-sidebar {
	margin-left: -40px;
	z-index: 999;
}
.collapsed-nav {
	width: 70px;
	margin-left: -10px;
	margin-top: -20px;
	overflow: hidden;
	z-index: 999;
}
.navbar-brand-collapsed {
	margin-left: 0px;
}
.navbar-brand-collapsed-img {
	width: 50px;
	height: 50px;
	margin-left: 7px;
	object-fit: contain;
}
.navbar-brand-img {
	width: 100px;
	height: 50px;
	margin-left: -10px;
	object-fit: contain;
}
.bottom-nav {
	width: 100%;
	height: 80px;
	left: 0%;
	bottom: 0;
	position: absolute;
}

.bottom-nav-on-collapse-ul {
	background-color: #e5e5e5;
	width: 100%;
	left: 0%;
	position: absolute;
	border-radius: 5px;
	bottom: 20px;
}

.collapsingIcon {
	width: 100%;
	/* left: 7%; */
	position: absolute;
	bottom: 10px;
}
.collapsingIcon:hover {
	background-color: #e5e5e5;
	width: 100%;
	left: 0%;
	position: absolute;
	border-radius: 5px;
	bottom: 10px;
}
.collapsed-nav .nav-bar-link.active i {
	color: #47bbf9;
}

.collapsed-nav li {
	margin: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	border-bottom: 1px solid #e5e5e5;
	padding-top: 8px;
	padding-bottom: 8px;
	border-radius: 0;
	z-index: 9;
}

.collapsed-nav li:hover {
	padding-top: 10px;
	background-color: #e5e5e5;
	width: 80%;
	margin-left: 2px;
	padding-bottom: 10px;
	border-radius: 5px;
	z-index: 9;
}

.collapsed-nav li i {
	font-size: 16px;
}

.collapsed-nav ul {
	padding: 0;
}

.collapsed-nav ul li span {
	display: none;
}

.side-dropdown-list {
	position: fixed;
	background-color: #f8f8f8;
	border-radius: 3px;
	border: 1px solid #d8d8d8;
	width: 140px;
	align-content: center;
	justify-content: center;
	min-height: 20px;
	padding-left: -50px;
	margin-top: 0px;
	margin-left: 10px;
	z-index: 999;
}

.side-dropdown-list ul {
	width: 100%;
	z-index: 999;
	background-color: #ffffff;
}
.side-dropdown-list ul li {
	font-size: 14px;
	width: 100%;
	padding-bottom: 10px;
	background-color: #ffffff;
	color: rgb(75, 73, 73);
	font-weight: 600;
	z-index: 999;
}

.side-dropdown-list ul li:hover {
	font-size: 15px;
	width: 90%;
	margin-left: 5%;
	background-color: #f3f3f3f3;
	color: rgb(4, 48, 87);
	font-weight: 600;
	z-index: 999;
}
