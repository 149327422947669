/***************************/

.home-btn {
	moz-transition: all 0.3s ease-in;
	-webkit-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
	text-decoration: none !important;
	font-size: 20px;
	padding: 10px 20px;
	font-weight: bold;
	border-radius: 5px;
	text-transform: capitalize;
	line-height: 1.1 !important;
}

.home-btn:hover,
.home-btn:focus {
	outline: none;
	border-color: white;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}

.home-btn-primary {
	background-color: #03a9f4;
	color: #ffffff;
	border-color: #03a9f4 !important;
}

.home-btn-primary:hover,
.home-btn-primary:focus {
	background-color: #ffffff !important;
	border-color: #059ce0 !important;
	color: #059ce0 !important;
}

.home-btn-white {
	background-color: #ffffff;
	border-color: #ffffff;
	color: #059ce0;
}

.btn-sm {
	font-size: 15px;
}

.home-container {
	max-width: 1170px;
}

/*****************************/

/*******************************************************************/

.form-control,
input.form-control,
input.form-control:focus {
	height: 40px;
	-webkit-box-shadow: none;
	box-shadow: none;
	border-color: #dfdfdf;
	color: #000000;
	outline: none;
	font-weight: 500;
	box-shadow: none;
	border-radius: 0;
}

select.form-control {
	border-color: #dfdfdf;
}

textarea.form-control {
	/* font-size: 14px; */
	resize: none;
}

textarea.form-control:not([rows]) {
	height: 140px;
}

/* placeholder color ======== */

.form-control::-webkit-input-placeholder,
::-webkit-input-placeholder {
	/* Edge */
	color: #cecece;
}

.form-control:-ms-input-placeholder,
:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #cecece;
}

.form-control::placeholder,
::placeholder {
	color: #cecece;
}

.form-control::-moz-placeholder {
	color: rgba(128, 128, 128, 0.7);

	opacity: 1;
}

.cursor-pointer {
	cursor: pointer;
}

/*scroll to top*/

.scrollToTop {
	bottom: 60px;
	color: #fff;
	display: none;
	font-size: 23px;
	height: 50px;
	line-height: 45px;
	position: fixed;
	right: 20px;
	text-align: center;
	text-decoration: none;
	-webkit-transition: all 0.5s ease 0s;
	-moz-transition: all 0.5s ease 0s;
	-ms-transition: all 0.5s ease 0s;
	-o-transition: all 0.5s ease 0s;
	transition: all 0.5s ease 0s;
	width: 50px;
	z-index: 999;
	background-color: #059ce0;
	opacity: 0.8;
}

.scrollToTop:hover,
.scrollToTop:focus {
	background-color: #059ce0;
	text-decoration: none;
	outline: none;
	opacity: 1;
	color: #fff;
}

/*Preloader*/
#aa-preloader-area {
	background-color: #f8f8f8;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
	/* display: none; */
}

.pulse {
	left: 47%;
	position: relative;
	top: 44%;
}

.pulse:before,
.pulse:after {
	content: "";
	width: 80px;
	height: 80px;
	border-radius: 500px;
	position: absolute;
	border: 5px solid #059ce0;
}

.pulse:before {
	-webkit-animation: pulse-outer 0.8s ease-in infinite;
	-moz-animation: pulse-outer 0.8s ease-in infinite;
	animation: pulse-outer 0.8s ease-in infinite;
}

.pulse:after {
	-webkit-animation: pulse-inner 0.8s linear infinite;
	-moz-animation: pulse-inner 0.8s linear infinite;
	animation: pulse-inner 0.8s linear infinite;
}

@-webkit-keyframes pulse-outer {
	0% {
		opacity: 1;
		filter: alpha(opacity=100);
	}

	50% {
		opacity: 0.5;
		filter: alpha(opacity=50);
	}

	100% {
		opacity: 0;
		filter: alpha(opacity=0);
	}
}

@-moz-keyframes pulse-outer {
	0% {
		opacity: 1;
		filter: alpha(opacity=100);
	}

	50% {
		opacity: 0.5;
		filter: alpha(opacity=50);
	}

	100% {
		opacity: 0;
		filter: alpha(opacity=0);
	}
}

@keyframes pulse-outer {
	0% {
		opacity: 1;
		filter: alpha(opacity=100);
	}

	50% {
		opacity: 0.5;
		filter: alpha(opacity=50);
	}

	100% {
		opacity: 0;
		filter: alpha(opacity=0);
	}
}

@-webkit-keyframes pulse-inner {
	0% {
		-webkit-transform: scale(0);
		opacity: 0;
		filter: alpha(opacity=0);
	}

	100% {
		-webkit-transform: scale(1);
		opacity: 1;
		filter: alpha(opacity=100);
	}
}

@-moz-keyframes pulse-inner {
	0% {
		-moz-transform: scale(0);
		opacity: 0;
		filter: alpha(opacity=0);
	}

	100% {
		-moz-transform: scale(1);
		opacity: 1;
		filter: alpha(opacity=100);
	}
}

@keyframes pulse-inner {
	0% {
		-webkit-transform: scale(0);
		-moz-transform: scale(0);
		-ms-transform: scale(0);
		-o-transform: scale(0);
		transform: scale(0);
		opacity: 0;
		filter: alpha(opacity=0);
	}

	100% {
		-webkit-transform: scale(1);
		-moz-transform: scale(1);
		-ms-transform: scale(1);
		-o-transform: scale(1);
		transform: scale(1);
		opacity: 1;
		filter: alpha(opacity=100);
	}
}

/* background shape ======= */

/* ==============height =====================*/
.h-100vh {
	height: 100vh !important;
}

.text-underline {
	text-decoration: underline !important;
}

.home-dropdown-menu {
	padding: 0 10px;
}

.home-dropdown-toggle::after {
	content: "\f107";
	border: 0;
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	margin-left: 0;
	vertical-align: initial;
	font-size: 20px;
}

.home-dropdown-item {
	padding: 10px 0;
}

.home-dropdown-item + .home-dropdown-item {
	border-top: 1px solid #dfdfdf;
}

/* ==================== media queries ============= */

@media (max-width: 991px) {
	h1,
	.h1 {
		font-size: 45px;
	}

	h2,
	.h2 {
		font-size: 35px;
		letter-spacing: 1px;
	}

	h3,
	.h3 {
		font-size: 28px;
	}

	h4,
	.h4 {
		font-size: 22px;
	}

	h5,
	.h5 {
		font-size: 18px;
	}

	h6,
	.h6 {
		font-size: 12px;
	}

	.fs-40 {
		font-size: 35px !important;
	}

	.fs-35 {
		font-size: 28px !important;
	}
}

.custom-accordion .custom-card {
	border: 0;
	border-bottom: 1px solid #dfdfdf;
	border-radius: 0;
}

.custom-accordion .custom-card .custom-card-header h5 .btn {
	padding: 10px 0;
	color: #059ce0;
	position: relative;
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.custom-accordion .custom-card .custom-card-header h5 .btn::after {
	content: "\f106";
	border: 0;
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	margin-left: 0;
	vertical-align: initial;
	font-size: 20px;
}

.custom-accordion .custom-card .custom-card-header h5 .btn.collapsed::after {
	content: "\f107";
}

.custom-accordion .custom-card .collapse .custom-card-body {
	padding: 0 15px 15px 0px !important;
}

.custom-accordion.custom-accordion-2 .custom-card .custom-card-header h5 .btn {
	padding: 18px 50px 18px 50px;
	color: #000000;
	position: relative;
	display: flex;
	justify-content: space-between;
	width: 100%;
	font-size: 20px;
}

.custom-accordion.custom-accordion-2 .custom-card .custom-card-header h5 .btn::after {
	content: "\f107";
	border: 0;
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	margin-left: 0;
	vertical-align: initial;
	font-size: 20px;
	position: absolute;
	left: 10px;
	top: 50%;
	transform: translateY(-50%);
	color: #dfdfdf;
}

.custom-accordion.custom-accordion-2 .custom-card .custom-card-header h5 .btn.collapsed::after {
	content: "\f105";
}

.custom-accordion.custom-accordion-2 .custom-card .collapse .custom-card-body {
	padding: 15px !important;
	padding-left: 50px !important;
}

.custom-tabs .nav {
	display: inline-flex;
	border: 0.5px solid #d2d0d0;
	border-radius: 10px;
	padding: 10px 14px;
	background-color: #ededed;
	transform: translate(-50%);
	left: 50%;
	position: relative;
}

.custom-tabs .nav .nav-item .nav-link {
	border-radius: 10px;
	padding: 15px 30px;
	font-weight: bold;
	font-size: 20px;
	color: #0a0a0a;
}

.custom-tabs .nav .nav-item .nav-link:hover,
.custom-tabs .nav .nav-item .nav-link.active {
	background-color: #059ce0;
	color: #ffffff;
}

/* ==================== media queries ============= */

@media (max-width: 991px) {
	h1,
	.h1 {
		font-size: 45px;
	}

	h2,
	.h2 {
		font-size: 35px;
		letter-spacing: 1px;
	}

	h3,
	.h3 {
		font-size: 28px;
	}

	h4,
	.h4 {
		font-size: 22px;
	}

	h5,
	.h5 {
		font-size: 18px;
	}

	h6,
	.h6 {
		font-size: 12px;
	}

	.fs-40 {
		font-size: 35px !important;
	}

	.fs-35 {
		font-size: 28px !important;
	}
}

@media (max-width: 767px) {
	body {
		font-size: 14px;
		line-height: 20px;
	}

	.custom-tabs .nav {
		padding: 5px;
		border-radius: 5px;
	}

	.custom-tabs .nav .nav-item .nav-link {
		border-radius: 10px;
		padding: 10px 15px;
		font-weight: bold;
		font-optical-sizing: 15px;
	}

	.custom-accordion.custom-accordion-2 .custom-card .custom-card-header h5 .btn {
		font-size: 16px;
		padding: 10px 30px;
		text-align: left;
	}
}

@media (max-width: 479px) {
	.custom-tabs .nav .nav-item .nav-link {
		font-size: 12px;
	}
}
